
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Home from "./pages/home/Home";
import "./style.scss";
import Privacy from "./pages/privacy/Privacy";
import { useEffect } from "react";

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "privacy",
      element: <Privacy />,
    },
    // {
    //   path: "contacts/:contactId",
    //   element: <Contact />,
    // },
  ]);

  // Add this useEffect to listen for route changes and scroll to top
  useEffect(() => {
    const unlisten = router.subscribe(({ pathname }) => {
      window.scrollTo(0, 0);
    });

    return () => {
      // Cleanup the listener when the component unmounts
      unlisten();
    };
  }, [router]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );

}

export default App;