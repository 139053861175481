import "./privacy.scss"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

const Privacy = () => {

    return (
        <div className="privacy">
            <Header />
            <div className="header-bg"></div>
            <div className="privacy-container">
                <h1>Privacy Policy</h1>
                <h2>Happie Privacy Policy</h2>
                <p>
                    Welcome to the Happie Privacy Policy (“Policy”)! This
                    explains how we collect, store, protect, and share your
                    information, and with whom we share it.
                </p>
                <p>
                    Whilst you’re enjoying the Happie mobile application
                    (including the desktop version) (“App”), our websites and
                    microsites (such as Happie.com) or using our digital
                    products and services (such as our competitions or surveys)
                    (together, referred to in this Privacy Policy as our
                    “Sites”), we collect some information about you. In
                    addition, you may choose to use the App or Sites to share
                    information with other users, including your friends and
                    contacts (“Users”). We may also need to share your
                    information sometimes.
                </p>
                <p>
                    The App and Sites are global, and your information will be
                    sent to and used in the United States and the UK regardless
                    of the country you reside in. This Policy explains how we
                    protect your personal data when we transfer it overseas, so
                    please read very carefully!
                </p>
                <h3>Who we are</h3>
                <p>
                    The App and Sites are operated by the “Happie” (also
                    referred to in this policy as “we” or “us”) which includes
                    Happie Holding Limited, Badoo Trading Limited and Happie
                    Trading LLC, all of which are controllers of personal
                    information collected and processed through the Happie App
                    and Sites.
                </p>

                <h2>1. COLLECTION OF INFORMATION.</h2>
                <h3>Registration Information</h3>
                <p>
                    When you download the App and create an account (“Account”),
                    we may collect certain information (“Registration
                    Information”) about you, such as:
                </p>
                <ul>
                    <li>Name;</li>
                    <li>Username;</li>
                    <li>Email address;</li>
                    <li>Cell number;</li>
                    <li>Gender identity;</li>
                    <li>Date of birth;</li>
                    <li>Sexual preference;</li>
                    <li>Photographs;</li>
                    <li>Location; and</li>
                    <li>
                        Login information for social media accounts that you
                        connect to your Happie Account (this could include, for
                        example, your Facebook and Instagram accounts). For more
                        information about this, see “Linking other Accounts to
                        Happie” in section 7 below.
                    </li>
                </ul>
                <p>
                    Once you register, you will be able to review and change
                    this information at any time just by logging in to Happie
                    (other than your date of birth and location (which, if you
                    have given Happie access to your location in your device
                    settings, is automatically updated based on the location of
                    your device)). It is your responsibility to ensure that your
                    account details are kept up to date. If your phone number
                    changes, please ensure that you update this in your account.
                </p>
                <p>
                    The information we collect helps to enhance the App and
                    verify our Users (robots are not welcome!). Registration
                    Information such as your sexual preference, name and
                    username may be visible to other Users who view your profile
                    page.
                </p>
                <p>
                    For Users who are California residents, the data we may
                    collect falls within the following categories of “personal
                    information”, as defined by the California Consumer Privacy
                    Act (CCPA):
                </p>
                <ul>
                    <li>A. Identifiers, such as name and location;</li>
                    <li>
                        B. Personal information, as defined in the California
                        customer records law, such as contact (including email and
                        telephone number) and financial information;
                    </li>
                    <li>
                        C. Characteristics of protected classifications under
                        California or federal law (if you choose to provide them),
                        such as age, gender identity, marital status, sexual
                        orientation, race, ancestry, national origin, religion,
                        and medical conditions;
                    </li>
                    <li>
                        D. Commercial information, such as transaction information
                        and purchase history;
                    </li>
                    <li>E. Biometric information;</li>
                    <li>
                        F. Internet or network activity information, such as
                        browsing history and interactions with our Sites and App;
                    </li>
                    <li>
                        G. Geolocation data, such as mobile device location;
                    </li>
                    <li>
                        H. Audio, electronic, visual and similar information, such
                        as photos and videos;
                    </li>
                    <li>
                        I. Professional or employment-related information, such as
                        work history and prior employer;
                    </li>
                    <li>J. Non-public education information; and</li>
                    <li>
                        K. Inferences drawn from any of the personal information
                        listed above to create a profile or summary about, for
                        example, an individual’s preferences and characteristics.
                    </li>
                </ul>
                <h3>Profile Information</h3>
                <p>
                    We recommend and encourage you (and all our members) to
                    think carefully about the information you disclose about
                    yourself. We also do not recommend that you put email
                    addresses, URLs, instant messaging details, phone numbers,
                    full names or addresses, credit card details, national
                    identity numbers, drivers’ license details and other
                    sensitive information which is open to abuse and misuse on
                    your profile.
                </p>
                <p>
                    When you post information about yourself or use the
                    messaging function to communicate with other Users, the
                    amount of personal information you share is at your own
                    risk. Please see Section 4 below for more information on who
                    can access what you post on Happie.
                </p>



                <h3>Geolocation Information</h3>
                <p>
                    If you have given Happie access to your location in your
                    device settings, when you use your cell, we will collect
                    information about WiFi access points as well as other
                    location information about your longitude and latitude and
                    may save your device’s coordinates to offer certain features
                    to you. This information helps us identify your physical
                    location and we use it to personalize the App and make it
                    easier for you to interact with other Users, by enabling the
                    general locality information to be displayed to Users seeing
                    your profile and showing you the profiles of other Users who
                    are near you.
                </p>
                <p>
                    If you have given Happie access to your location, but wish
                    to turn this off, you can do so by the following methods:
                </p>
                <ol>
                    <li>
                        iPhone app — settings, privacy, location services, Happie
                    </li>
                    <li>
                        Android — settings, location, Happie, permissions,
                        location
                    </li>
                </ol>
                <h3>Device Information</h3>
                <p>
                    We may collect information about your device when you use
                    the App including the unique device identifier, device
                    model, and operating system, for a number of purposes, as
                    set out in this policy. In addition, if you permit us to do
                    so, the App may access your device’s address book solely in
                    order to add someone to your contacts.
                </p>
                <h3>Links</h3>
                <p>
                    We may keep track of how you interact with links available
                    on Happie including third party services by redirecting
                    clicks or through other means. We may share aggregate click
                    statistics such as how many times a particular link was
                    clicked on.
                </p>
                <h3>Messages</h3>
                <p>
                    We review the content of messages sent in the App to
                    identify topics, sentiments, and trends across our Users. We
                    will take steps to remove personally identifying information
                    from such messages, prior to reviewing them. We will not
                    share the content of User messages or information we derive
                    from them with any third party.
                </p>
                <h3>
                    Happie Success Stories, Surveys and other Contributions
                </h3>
                <p>
                    From time to time, we run surveys for research purposes and
                    we may contact you to find out if you would like to take
                    part. We may also contact you to find out if you would like
                    to provide feedback, a testimonial or take part in marketing
                    campaigns (for example, if you let us know that you have
                    found a match on the App, we may contact you to ask if you
                    would be happy to feature in advertising for Happie). Such
                    surveys and marketing campaigns are optional and more
                    information will be provided at the point of contact. If you
                    do not wish to be contacted to take part in a survey or
                    marketing campaign, please contact our Customer Support Team
                    via our
                    <a href="https://happie.social/contact-us">Feedback Page</a>.
                </p>
                <h3>When you Contact Customer Support</h3>
                <p>
                    If you contact our Customer Support team via our
                    <a href="https://happie.social/contact-us">Feedback Page</a>,
                    we will receive your email address, and may track your IP
                    address, as well as the information you send to us to help
                    resolve your query. We will keep records of our
                    communications with you, including any complaints that we
                    receive from you about other Users (and from other Users
                    about you) for 6 years after deletion of your account.
                </p>
                <h3>Cookies and similar technologies</h3>
                <p>
                    When you visit our Sites or when you use our App, we may
                    collect personal data from you automatically by using
                    cookies or similar technologies. A cookie is a small file
                    that can be placed on your device or browser that allows us
                    to recognize and remember you.
                </p>

                <h2>2. USE OF YOUR INFORMATION.</h2>
                <p>
                    Our main goal is to ensure your experience on Happie is an
                    enjoyable one and you don’t end up getting stung! In order
                    to deliver an enjoyable experience to you, we may use your
                    Registration and other information to:
                </p>
                <ul>
                    <li>offer you our services and features;</li>
                    <li>
                        contact you with information about the App (e.g., updates
                        and new features);
                    </li>
                    <li>
                        personalize the App/Sites and the content we deliver to
                        you;
                    </li>
                    <li>
                        conduct research and analytics about how you use and
                        interact with the App/Sites;
                    </li>
                    <li>
                        to test new technologies and processes designed to enhance
                        and improve the App/Sites;
                    </li>
                    <li>
                        resolve disputes, troubleshoot problems and to enforce our
                        Terms &amp; Conditions;
                    </li>
                    <li>
                        investigate fraud, protect our legal rights, and to
                        enforce our Terms &amp; Conditions.
                    </li>
                    <li>
                        to send you information about the promotions and offers we
                        have available (e.g., specify the types of
                        goods/services/offers to be provided via direct marketing)
                        by direct marketing or other modes of communication - if
                        you’ve signed up for our communications or otherwise told
                        us it’s OK. We will not use your information in email
                        direct marketing unless you give us your consent during
                        the Account creating process or via Settings in the App
                        (you can withdraw from marketing at any time via Settings
                        in the App or by using the opt-out mechanisms and links
                        provided in each message); and
                    </li>
                    <li>protect our Users and third parties from harm.</li>
                </ul>
                <h3>Our Matching Algorithms</h3>
                <p>
                    We have developed matching algorithms to predict your
                    compatibility with other users and so we can show you people
                    we think are a good match for you. Our matching algorithms
                    use the following data about you to predict your
                    compatibility with others and generate profile
                    recommendations: the things you tell us about yourself in
                    your profile; information about your activity in our apps;
                    whether you are a photo verified user; and your device
                    coordinates, which are necessary to understand your
                    proximity to other members. The legal basis for processing
                    profile and proximity information is that this is necessary
                    for the provision of our contractual services to you
                    pursuant to Article 6(1)(b) GDPR. Where we process
                    information about your app activity, this is based on our
                    legitimate interests pursuant to Article 6(1)(f) GDPR, and
                    specifically our legitimate interest in generating more
                    personalized and relevant recommendations for who you can
                    match with in our App. If you have chosen to include
                    sensitive information in your profile, such as information
                    about your beliefs, the processing of this information is
                    based on your voluntary and explicit consent.
                </p>
                <h3>Moderation Practices</h3>
                <p>
                    We use a combination of automated systems and a team of
                    moderators to monitor and review accounts (including photos
                    and any other information uploaded onto user profiles) and
                    messages for content that indicates breaches of our Terms
                    and Conditions of Use. If an account or message meets
                    certain criteria that demonstrate that the Terms and
                    Conditions of Use are likely to have been breached, the
                    relevant account will be subject to a warning and the user’s
                    access restricted and/or blocked. Affected Users can contact
                    Happie to contest the decision.
                </p>
                <p>
                    <b
                    >If you post anything that is inconsistent with our Terms
                        and Conditions of Use, we reserve the right to terminate
                        or restrict access to your Account.</b
                    >
                </p>

                <h3
                    id="Processing-we-perform-to-improve-services-for-students"
                >
                    Processing We Perform To Improve Services For Students
                </h3>
                <p>
                    <b>What we’re doing.</b> We process personal data to
                    understand which of our users may be in college or
                    university. To do this, we look at historic location data to
                    understand whether your device’s coordinates match to a
                    general university or college area. For this activity, we
                    use recent historic data regarding where you opened the
                    Happie app and we’ll keep a simple “at campus yes/no” result
                    based on our findings. <b>What we don’t do.</b> We do not
                    track your real-time movements as part of this activity and
                    we do not identify or store information about where you are
                    studying. To protect your privacy, we do not retain any of
                    the location data used for this activity and it is
                    permanently deleted once the results are generated.
                    <b>Why we’re doing this.</b> We’ll combine the results of
                    this processing with other relevant information we hold for
                    the following purposes: so we can send the right offers,
                    events and features to the right users; to understand which
                    Happie events are popular with the student demographic and
                    which aren’t working so well; and to improve the design of
                    the App to meet the needs of students. The legal basis for
                    this processing is our legitimate interests. We have a
                    legitimate interest in providing a more tailored app
                    experience for student users and in optimizing their chances
                    of finding the right connections. Please note this
                    processing only affects Happie App users who identified the
                    United States or Canada as their location when registering
                    for the App (we exclude users in Quebec) and who are in the
                    18 to 24 age group.
                </p>

                <h2>3. WHAT OTHERS MAY SEE ABOUT YOU.</h2>
                <p>
                    We think our Users are awesome, and we want you to share how
                    awesome you are with the world, so we have built certain
                    features to enable this. Our App is designed to make it
                    easier for you to connect with other Users and to interact
                    with them.
                </p>
                <p>
                    When using the Happie App, you should assume that
                    <b>anything</b> you post or submit on the App may be
                    publicly-viewable and accessible, both by Users and
                    non-users of the App. We want our Users to be careful about
                    posting information that may eventually be made public.
                </p>
                <p>
                    Please be careful about posting sensitive details about
                    yourself on your profile such as your religious denomination
                    and health details. You may also choose to add sensitive
                    information about yourself when you add certain Happie
                    badges to your profile, such as your religion and political
                    leanings. While you may voluntarily provide this information
                    to us when you create your profile, including your sexual
                    preferences, there is no requirement to do so. Please
                    remember that photographs that you post on Happie may reveal
                    information about yourself as well.
                    <b
                    >Where you do upload and choose to tell us sensitive
                        information about yourself, including through the addition
                        of badges to your profile, you are explicitly consenting
                        to our processing of this information and making it public
                        to other Users.</b
                    >
                </p>
                <p>
                    Your Happie profile and other information you make available
                    via the App, including certain information added to your
                    profile through third party accounts (such as Facebook,
                    Instagram or Spotify – see section 7 of this Privacy Policy
                    for more information about this) may be viewed and shared by
                    Users with individuals who may or may not be users of the
                    App. For example, a User may recommend you as a match to his
                    or her Facebook friend(s) by taking a screenshot of your
                    profile picture and sharing it, regardless of whether such
                    friend(s) is also a User of the App.
                </p>
                <p>
                    Please also note that in certain countries (currently, South
                    Korea only), Users are provided with functionality enabling
                    them to download a copy of their conversation history (as
                    this is required by local laws) and that Happie has no
                    control over how users may then publish or use that
                    information.
                </p>
                <p>
                    If you log in to or access the App through your Facebook
                    account or another third-party account on a device which you
                    share with others, remember to log out of the App and the
                    relevant third-party account when you leave the device in
                    order to prevent other users from accessing your Happie
                    account.
                </p>
                <h2>4. OUR POLICY TOWARDS AGE.</h2>
                <p>
                    Although we want as many people as possible to enjoy our
                    creation, you have to be at least 18 years old to use
                    Happie.
                </p>
                <p>
                    Happie does not knowingly collect any information about or
                    market to children, minors or anyone under the age of 18. If
                    we become aware that a child, minor or anyone under the age
                    of 18 has registered with us and provided us with personal
                    information, we will take steps to terminate that person’s
                    registration.
                </p>
                <h2>5. SECURITY.</h2>
                <p>
                    Here at Happie, we pride ourselves on taking all appropriate
                    security measures to help protect your information against
                    loss, misuse and unauthorized access, or disclosure. We use
                    reasonable security measures to safeguard the
                    confidentiality of your personal information such as secured
                    servers using firewalls.
                </p>
                <p>
                    Unfortunately, no website or Internet transmission is ever
                    completely 100% secure and even we cannot guarantee that
                    unauthorized access, hacking, data loss or other breaches
                    will never occur, but here are some handy tips to help keep
                    your data secure:
                </p>
                <ol>
                    <li>
                        Please make sure you log out of your Account after use as
                        you never know who may stumble onto your Account!
                    </li>
                    <li>
                        Please don’t share the password you use to access your
                        Happie Account with anyone else!
                    </li>
                    <li>Change your password periodically.</li>
                </ol>


                <h2>6. YOUR CALIFORNIA PRIVACY RIGHTS.</h2>
                <p>
                    For Users who are California residents, you have the
                    following rights (in addition to those listed at section 10
                    below) under the California Consumer Privacy Act, and you
                    have the right to be free from unlawful discrimination for
                    exercising your rights under the Act:
                </p>
                <ol>
                    <li>
                        You have the right to request that we disclose certain
                        information to you and explain how we have collected, used
                        and shared your personal information over the past 12
                        months.
                    </li>
                    <li>
                        You have the right to request that we delete your personal
                        information that we collected from you, subject to certain
                        exceptions.
                    </li>
                </ol>
                <p>
                    California’s “Shine the Light” law, Civil Code section
                    1798.83, requires certain businesses to respond to requests
                    from California customers asking about the businesses’
                    practices related to disclosing personal information to
                    third parties for the third parties’ direct marketing
                    purposes. If you wish to find out about any rights you may
                    have under California Civil Code section 1798.83, you can
                    write to us at
                    <a href="mailto:support@happie.social"
                    >support@happie.social</a
                    >.
                </p>
                <p>
                    From time to time, as part of a joint promotion with a third
                    party, we may, if you participate in such promotion,
                    disclose your contact information to the third party to
                    allow them to market their products or services to you.
                    Where this is a condition for participation in a promotion,
                    we will always let you know before when you enter the
                    promotion. Please follow the instructions provided to you by
                    third parties to unsubscribe from their messages.
                </p>
                <p>
                    In addition, under California law, operators of online
                    services are required to disclose how they respond to “do
                    not track” signals or other similar mechanisms that provide
                    consumers the ability to exercise choice regarding the
                    collection of personal information of a consumer over time
                    and across third party online services, to the extent the
                    operator engages in that collection. At this time, we do not
                    track our Users’ personal information over time and across
                    third-party online services. This law also requires
                    operators of online services to disclose whether third
                    parties may collect personal information about their users’
                    online activities over time and across different online
                    services when the users use the operator’s service. We do
                    not knowingly permit third parties to collect personal
                    information about an individual User’s online activities
                    over time and across different online services when using
                    the App.
                </p>
                <h2>7. YOUR RIGHTS.</h2>
                <p>
                    Privacy laws applicable in your country may give you the
                    following rights:
                </p>
                <ol>
                    <li>
                        Right to be informed: what personal data an organization
                        is processing and why (we provide this information to you
                        in this Privacy Policy).
                    </li>
                    <li>
                        Right of access: you can request a copy of your data.
                    </li>
                    <li>
                        Right of rectification: if the data held is inaccurate,
                        you have the right to have it corrected.
                    </li>
                    <li>
                        Right to erasure: you have the right to have your data
                        deleted in certain circumstances.
                    </li>
                    <li>
                        Right to restrict processing: in limited circumstances,
                        you have the right to request that processing is stopped
                        but the data retained.
                    </li>
                    <li>
                        Right to data portability: you can request a copy of
                        certain data in a machine-readable form that can be
                        transferred to another provider.
                    </li>
                    <li>
                        Right to object: in certain circumstances (including where
                        data is processed on the basis of legitimate interests or
                        for the purposes of marketing) you may object to that
                        processing.
                    </li>
                    <li>
                        Rights related to automated decision-making including
                        profiling: there are several rights in this area where
                        processing carried out on a solely automated basis results
                        in a decision which has legal or significant effects for
                        the individual. In these circumstances your rights include
                        the right to ensure that there is human intervention in
                        the decision-making process.
                    </li>
                </ol>
                <p>
                    The particular rights which are applicable to you (which
                    might include other rights not listed above) may vary
                    depending on your country. You should make yourself aware of
                    the rights you have under applicable privacy laws in your
                    country.
                </p>
                <p>
                    If you want to exercise any of your rights listed above
                    please visit our
                    <a href="https://happie.social/contact-us">Feedback Page</a> or
                    email us at
                    <a href="mailto:support@happie.social">support@happie.social</a>. You can
                    also contact us by post at the following address: The
                    Broadgate Tower Third Floor, 20 Primrose Street, London,
                    United Kingdom, EC2A 2RS. For your protection and the
                    protection of all of our Users, we may need to request
                    specific information from you to help us confirm your
                    identity before we can answer the above requests.
                </p>
                <p>
                    If you have a concern about how we have processed your
                    request or your personal data, you should contact us in the
                    first instance via the contact details listed above.
                </p>
                <p>
                    If you feel we have not resolved your concern, you have the
                    right to make a complaint at any time to the Information
                    Commissioner’s Office (ICO), the UK supervisory authority
                    for data protection issues (<a style={{marginLeft:'0'}} href="http://www.ico.org.uk/"
                    >www.ico.org.uk</a
                    >). If you live in a country or territory located in the
                    European Union (EU) or European Economic Area (EEA), you may
                    also get in touch with your local
                    <a
                        href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080"
                    >Data Protection Regulator</a
                    >. If you live in a country outside the EU, you may have the
                    right to lodge a complaint with your local privacy or data
                    protection regulator.
                </p>
                <p><b>EU Representative</b></p>
                <p>
                    Pursuant to Article 27 of the General Data Protection
                    Regulation (GDPR), Happie has appointed Borlux Ltd as its
                    GDPR representative in the EU. If you usually reside in an
                    EU Member State you can contact Borlux Ltd regarding matters
                    pertaining to the GDPR by:
                </p>
                <p>
                    Using the online request form at
                    https://services.nathantrust.com/privacycontact
                </p>
                <p>Postal address: 1st Floor, 6 Lapps Quay, Cork, Ireland</p>

                <h2>8. CHANGES TO THIS POLICY.</h2>
                <p>
                    As Happie evolves, we may revise this Privacy Policy from
                    time to time. The most current version of the policy will
                    govern our use of your information and will always be at
                    <a href="https://happie.social/privacy"
                    >happie.social/privacy</a
                    >. If we make a change to this policy that, in our sole
                    discretion, is material, we will notify you, for example,
                    via an email to the email associated with your Account or by
                    posting a notice within Happie.
                </p>
                <h3>Effective date</h3>
                <p>This Privacy Policy was last updated July 13th, 2023.</p>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy