import "./header.scss"
import { ReactComponent as Logo } from "../../assets/happie-01.svg"
import LogoImg from "../../assets/logo.png"

import { Link } from "react-router-dom"

const Header = () => {
  return (    
    <header className="header">
      <div className="wrapper element-to-animate">
        <div className="logo"><Link to="/"><img src={LogoImg} alt="Happie Social" /> <Logo /></Link></div>
      </div>
    </header>
  )
}

export default Header