import { useRef, useState, useEffect } from 'react'
import "./home.scss"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"
import SubscriptionForm from '../../components/subscriptionForm/subscriptionForm';

import scientists from "../../assets/love-fingers.jpg"
import imageCTA1 from "../../assets/happy-couple-1.jpg"
import imageCTA2 from "../../assets/happy-couple-2.jpg"
import imageCTA3 from "../../assets/happy-couple-3.jpg"
import playstoreBtn from "../../assets/button-google-play.png"
import appstoreBtn from "../../assets/button-app-store.png"
import { Link } from 'react-router-dom'

import Reveal from "../../components/reveal"

const Home = () => {
  const [showReview, setShowReview] = useState(0);
  const [showTestimonials, setShowTestimonials] = useState(false);
  const testimonialsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setShowTestimonials(entry.isIntersecting);
    });

    observer.observe(testimonialsRef.current)
  }, [])

  const handlePagination = (e) => {
    setShowReview(e);
  }

  useEffect(() => {
    if (showTestimonials) {
      setShowReview(1)
    }
  }, [showTestimonials])

  useEffect(() => {
    const interval = setTimeout(() => {
      if (showReview < 3) {
        setShowReview((count) => count + 1);
      } else {
        setShowReview(1);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [showReview])

  return (
    <div className="home">
      <Header />
      <main className="main">
        <div className="hero">
          <div className="wrapper">
            <h1 className="title"><Reveal>A Relationship platform {"\n"}<span> for the rest of us</span></Reveal></h1>
            <Reveal><SubscriptionForm /></Reveal>
            <div className="scroll-down">
              <Reveal delay={0.5}>
                <svg
                  width="25px"
                  height="100%"
                  viewBox="0 0 247 390"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  style={{
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: '1.5',
                  }}
                >
                  <path
                    id="wheel"
                    d="M123.359,79.775l0,72.843"
                    style={{ fill: 'none', stroke: '#fff', strokeWidth: '20px' }}
                  ></path>
                  <path
                    id="mouse"
                    d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
                    style={{ fill: 'none', stroke: '#fff', strokeWidth: '20px' }}
                  ></path>
                </svg>
                <p>Scroll down</p>
              </Reveal>
            </div>
          </div>
        </div>
        <div className="scientists">
          <div className="left"><img src={scientists} alt="Two persons forming love fingers" /></div>
          <div className="right">
            <div className="subtitle"><Reveal>Happie Social</Reveal></div>
            <h2 className="title"><Reveal delay={0.5}>We love science.</Reveal></h2>
            <div className="text"><Reveal delay={0.75}>Our Happie Social researchers, behavioral analysts, and matchmakers study daters and compatibility so we can make Happie better for you. We’ve gotten pretty good at it.</Reveal>
            </div>
          </div>
        </div>
        <div className="testimonials" ref={testimonialsRef}>
          <div className="wrapper">
            <div className="subtitle"><Reveal>What Our Users Say</Reveal></div>
            <Reveal delay={0.5}><div className="reviews">
              <div className="review" onClick={() => handlePagination(2)} style={{ display: showReview === 1 ? 'flex' : 'none' }}>
                <div className="quote-icon"><svg height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M25 11.749C25.4348 6.96234 29.0217 3.91631 35 2.93723V0C24.4565 0.761503 18.6957 7.17991 18.6957 15.8828C18.6957 21.7573 21.7391 26 26.9565 26C31.3043 26 34.6739 23.0628 34.6739 18.6025C34.6739 14.6862 32.1739 12.4017 29.0217 11.749H25ZM6.19565 11.749C6.73913 6.96234 10.2174 3.91631 16.3043 2.93723V0C5.76087 0.761503 0 7.17991 0 15.8828C0 21.7573 3.04348 26 8.26087 26C12.5 26 15.9783 23.0628 15.9783 18.6025C15.9783 14.6862 13.4783 12.4017 10.3261 11.749H6.19565Z" fill="currentColor"></path></svg></div>
                <div className="text">Happie’s prompts really made the difference—I felt like I got a good sense of a guy’s vibe from his answers, and it was easy to jump right into a real conversation.</div>
                <div className="author">Jake C.</div>
              </div>
              <div className="review" onClick={() => handlePagination(3)} style={{ display: showReview === 2 ? 'flex' : 'none' }}>
                <div className="quote-icon"><svg height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M25 11.749C25.4348 6.96234 29.0217 3.91631 35 2.93723V0C24.4565 0.761503 18.6957 7.17991 18.6957 15.8828C18.6957 21.7573 21.7391 26 26.9565 26C31.3043 26 34.6739 23.0628 34.6739 18.6025C34.6739 14.6862 32.1739 12.4017 29.0217 11.749H25ZM6.19565 11.749C6.73913 6.96234 10.2174 3.91631 16.3043 2.93723V0C5.76087 0.761503 0 7.17991 0 15.8828C0 21.7573 3.04348 26 8.26087 26C12.5 26 15.9783 23.0628 15.9783 18.6025C15.9783 14.6862 13.4783 12.4017 10.3261 11.749H6.19565Z" fill="currentColor"></path></svg></div>
                <div className="text">Thank you Happie! We’re getting married in a few months!</div>
                <div className="author">Kathryn B.</div>
              </div>
              <div className="review" onClick={() => handlePagination(1)} style={{ display: showReview === 3 ? 'flex' : 'none' }}>
                <div className="quote-icon"><svg height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M25 11.749C25.4348 6.96234 29.0217 3.91631 35 2.93723V0C24.4565 0.761503 18.6957 7.17991 18.6957 15.8828C18.6957 21.7573 21.7391 26 26.9565 26C31.3043 26 34.6739 23.0628 34.6739 18.6025C34.6739 14.6862 32.1739 12.4017 29.0217 11.749H25ZM6.19565 11.749C6.73913 6.96234 10.2174 3.91631 16.3043 2.93723V0C5.76087 0.761503 0 7.17991 0 15.8828C0 21.7573 3.04348 26 8.26087 26C12.5 26 15.9783 23.0628 15.9783 18.6025C15.9783 14.6862 13.4783 12.4017 10.3261 11.749H6.19565Z" fill="currentColor"></path></svg></div>
                <div className="text">Other dating apps were like shooting fish in a barrel. But my girlfriend and I clicked right away on Happie, and the conversation was effortless. We’ve been together for over a year.</div>
                <div className="author">Diana V.</div>
              </div>
              <div className="pagination">
                <button onClick={() => handlePagination(1)}><div className={showReview === 1 ? 'animatebg' : ''}></div></button>
                <button onClick={() => handlePagination(2)}><div className={showReview === 2 ? 'animatebg' : ''}></div></button>
                <button onClick={() => handlePagination(3)}><div className={showReview === 3 ? 'animatebg' : ''}></div></button>
              </div>
            </div></Reveal>
          </div>
        </div>
        <div className="work-with-us">
          <div className="wrapper">
            <div className="left">
              <div className="subtitle">
              <Reveal>Stay Happie</Reveal>
              </div>
              <h2 className="title">
              <Reveal delay={0.5}>Let love in</Reveal>
              </h2>
              <div className="description">
              <Reveal delay={0.75}>Love is in the air, no need to fall!</Reveal>
              </div>
              <Reveal delay={1}><div className="download-buttons">
                <a href="https://play.google.com/store/apps/details?id=com.dating.social.stake" target="_blank" rel="noreferrer" className='mobile-btn'><img src={playstoreBtn} alt="Google Play Button" /></a>
                <a href className='mobile-btn'><img src={appstoreBtn} alt="App Store Button" /></a>
              </div></Reveal>
            </div>
            <div className="right">
            <Reveal><img src={imageCTA1} alt="Man in white polo shirt kissing woman in black and pink floral dress" /></Reveal>
            <Reveal delay={0.5}><img className="middle" src={imageCTA2} alt="Silhouette of woman standing on beach during sunset" /></Reveal>
            <Reveal delay={0.75}><img src={imageCTA3} alt="Man hugging woman inside garden house" /></Reveal>
            </div>
          </div>
        </div>
        <div className="press">
          <div className="wrapper">
            <div className="subtitle"><Reveal>Press</Reveal></div>
            <h2 className="title"><Reveal>Happie, here, now!</Reveal></h2>
            <div className="description"><Reveal>See our recent updates, and how we secure your personal data.</Reveal></div>
            <Reveal><Link className="btn" to="/privacy" preventScrollReset={true}>Privacy Policy</Link></Reveal>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default Home