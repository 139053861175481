import './footer.scss'
import { ReactComponent as Logo } from "../../assets/happie-01.svg"
import { Link } from "react-router-dom"
import Reveal from '../reveal';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="column-1 element-to-animate">
          <div className="logo"><Reveal><Link to="/"><Logo /></Link></Reveal></div>
          <div className="copyright"><Reveal>© {currentYear} Happie Inc.</Reveal></div>
        </div>
        <div className="column-2 element-to-animate">
          {/* <div className="nav-header">Index</div>
          <nav>
            <a href="#mission">Mission</a>
            <a href="#careers">Careers</a>
            <a href="#labs">Labs</a>
            <a href="#press">Press</a>
            <a href="#blog">Blog</a>
            <a href="#contact">Contact</a>
          </nav> */}
        </div>
        <div className="column-3 element-to-animate">
          {/* <div className="nav-header">Resources</div>
          <nav>
            <a href="#safe-dating-tips">Safe Dating Tips</a>
            <a href="#faq">FAQ</a>
            <a href="#trust-safety">Trust & Safety</a>
          </nav> */}
        </div>
        <div className="column-4 element-to-animate">
          <Reveal>
            <div className="nav-header">Legal</div>
            <nav>
              <a href="#security">Security</a>
              <a href="#terms">Terms</a>
              <Link to="/privacy">Privacy Policy</Link>
            </nav>
          </Reveal>
        </div>
      </div>
    </footer>
  )
}

export default Footer