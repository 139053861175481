import axios from 'axios';
import React, { useState } from 'react';
import "./subscriptionForm.scss"

function SubscriptionForm() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const isEmailValid = (email) => {
    // Regular expression to validate an email address
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handleSubscribe = async () => {
    if (!isEmailValid(email)) {
        setMessage('Invalid email address.');
        return;
      }
      
    try {
      // Make an HTTP POST request to the Firebase Function
      const response = await axios.post(
        'https://us-central1-stake-a29fa.cloudfunctions.net/mailchimp/subscribe',
        { email }
      );

      // Handle the response from the Firebase Function, e.g., show success or error message
      if (response.data && response.data.error) {
        console.log(`Error: ${response.data.error}`);
        setMessage(`Error: ${response.data.error}`);
      } else {
        console.log('Subscription successful!');
        setMessage('You were added to our list.');
        setEmail('');
      }
    } catch (error) {
      // Handle errors, e.g., network issues
      console.error('Network error. Please try again.');
      setMessage('Network error. Please try again.');
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission
      handleSubscribe(); // Call the submit function
    }
  };

  return (
    <div className='subscriptionForm'>
        <label>Join our waiting list</label>
      <input
        type="email"
        placeholder="jon.doe@example.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <button onClick={handleSubscribe}>Submit</button>
      <p className="message">{message}</p>
    </div>
  );
}

export default SubscriptionForm;
